@import url("https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&family=Roboto+Condensed:wght@300;400&display=swap");

body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.leaflet {
  height: 300px;
  width: 100%;
}

.site-result-error-icon {
  color: red;
}

.ant-input-number {
  width: 100% !important;
}

.ant-form-item-label > label {
  align-items: baseline !important;
}

.ant-card-bordered {
  border-top: none !important;
}

.ant-btn-link {
  color: #000 !important;
}

.leaflet-top.leaflet-left {
  z-index: 900;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 900;
  pointer-events: none;
}

#root {
  .ant-input,
  .ant-select-selector,
  .ant-input-number,
  .ant-picker,
  .ant-input-password {
    border: 1px solid #6a6a6a;
    border-radius: 5px;
  }

  .ant-form-item-has-error {
    .ant-input,
    .ant-input-number,
    .ant-input-number {
      border: 1px solid #ff4d4f;
    }
  }

  .ant-checkbox-inner {
    border: 1px solid #6a6a6a;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #6a6a6a;
    }
  }

  .ant-radio-inner {
    border-color: #6a6a6a;

    &::after {
      background-color: #6a6a6a;
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      min-height: 250px;
      margin: auto;
      border: 2px dashed #c7c7c7;
      border-radius: 5px;
    }

    .ant-upload {
      &.ant-upload-btn {
        margin: auto;
        height: 250px;
        font-weight: bold;
        padding: 0px;

        div {
          &.image-container {
            margin: auto;
            width: 100%;
            height: 250px;
            background-size: cover;
            background-position: center;
            filter: opacity(0.2) blur(1px);
            -webkit-filter: opacity(0.2) blur(1px);
          }
        }

        div {
          &.text-container {
            width: 100%;
            height: 250px;
            top: 0px;
            position: absolute;

            p {
              font-family: "Assistant", sans-serif;

              &.ant-upload-drag-icon {
                font-size: 36px;
                color: #6a6a6a;
                padding-top: 50px;
              }

              &.ant-upload-text {
                color: #6a6a6a;
              }
            }
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper {
    &.ant-input-password {
      svg {
        color: #6a6a6a;

        &:hover {
          color: #222;
        }
      }
    }
  }

  .ant-input-password {
    .ant-input {
      border: none !important;
    }
  }
}
