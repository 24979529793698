#root {
  height: 100vh;
}

.container {
  position: relative;
  font-family: "Roboto Condensed", sans-serif;
  letter-spacing: -0.2px;
  background: #fff;
  min-height: 100%;
}

.form-header {
  padding: 0px 20px;
  background: #fff;
  height: 95px;

  &.sticky {
    top: 0px;
    position: sticky;
    position: -webkit-sticky;
    min-width: 100%;
    z-index: 1000;
    border-bottom: 1px solid #f0f0f0;
  }

  .form-header-container {
    padding: 22px 0px;

    .right {
      font-family: "Roboto Condensed", sans-serif;

      h1 {
        font-family: "Assistant", sans-serif;
        padding: 0px;
        margin: 0px;
        font-size: 18px;
        font-weight: bold;
        display: inline-block;

        &.logo {
          font-size: 16px;
          line-height: 48px;
          padding: 0px 10px;
          margin-right: 20px;
          border: 1px solid #f0f0f0;
          background: #f3f3f3;
          border-radius: 5px;
        }
      }
    }

    .left {
      text-align: right;

      .datapoint {
        display: inline-block;
        padding-right: 25px;
        font-weight: bold;
        line-height: 25px;

        .icon {
          font-size: 24px;
          margin-bottom: -6px;
        }
      }

      button {
        font-weight: bold;
        font-size: 14px;
        border-radius: 5px;

        &.submit {
          background-color: #2b78e4;
          border-color: #2b78e4;
          color: #fff;
          padding-left: 25px;
          padding-right: 25px;
          margin-left: 20px;
        }

        &.lang {
          text-transform: uppercase;
        }
      }

      .more {
        font-weight: bold;
        font-size: 35px;
        vertical-align: middle;
        margin-bottom: 5px;
        margin-left: 20px;
      }
    }
  }
}

.ant-dropdown {
  ul.menu-dropdown {
    margin-top: 20px;
    margin-right: 20px;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    padding: 0px;

    li.ant-dropdown-menu-item {
      font-family: "Assistant", sans-serif;
      min-width: 200px;
      padding: 20px;
      font-weight: bold;
    }
  }
}

.form-header h1,
.form-header .left .datapoint,
.field-group .field-group-header,
.sidebar .sidebar-list,
.ant-dropdown ul.menu-dropdown li.ant-dropdown-menu-item,
.sidebar .sidebar-header {
  color: #6a6a6a;
}

.sidebar {
  &.sticky {
    top: 95px;
    position: sticky;
    position: -webkit-sticky;
    height: fit-content;
  }

  &.mobile {
    .ant-drawer-body {
      padding: 0;
    }
  }

  .sidebar-header {
    text-transform: uppercase;
    font-family: "Assistant", sans-serif;
    font-size: 12px;
    font-weight: bold;
    padding: 20px;
  }

  .sidebar-list {
    cursor: pointer;
    font-family: "Assistant", sans-serif;
    padding: 18px 20px;
    font-size: 14px;
    font-weight: bold;
    border: none;

    &.active {
      background: #f3f3f3;
      color: #222;
    }

    &.complete {
      color: green;
    }

    .icon {
      margin-right: 10px;
      padding: 0px;
      margin-bottom: -2px;

      &.icon-right {
        float: right;
        margin-top: 4px;
      }
    }

    .ant-space.ant-space-vertical {
      width: 100%;
    }

    .sidebar-incomplete-text {
      margin-left: 24px;
      color: #cf9b63;
    }
  }
}

.icon {
  margin-right: 10px;
  padding: 0px;
  margin-bottom: -2px;

  &.required {
    margin-left: 5px;
    font-size: 7px;
    vertical-align: super;
    color: #d74439;
  }
}

.main {
  min-height: calc(100vh - 95px);
}

.login-container {
  padding: 0 12px;
  font-family: "Assistant", sans-serif;

  .login-header-wrapper {
    padding: 55px 0 15px 0;
    background-color: #fff;
  }

  .login-header {
    h1 {
      color: #6f6f6f;
      padding: 0px;
      margin: 0px;
      font-size: 20px;
      font-weight: bold;
      display: inline-block;

      &.logo {
        margin-right: 20px;

        img {
          height: 48px;
          width: 48px;
          border-radius: 5px;
        }
      }
    }
  }

  .survey-title-wrapper {
    h2 {
      margin-top: 45px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .login-form {
    margin-top: 56px;

    .login-form-title {
      margin-bottom: 24px;

      h4 {
        font-size: 16px;
      }

      .ant-typography-danger {
        font-size: 15px;
      }
    }

    h4,
    label {
      font-weight: 600;
    }

    label {
      font-size: 15px;

      &::before {
        display: none !important;
      }

      .ant-typography {
        font-size: 14px;
        font-weight: 500;
      }
    }

    button {
      margin-top: 8px;
    }
  }

  .login-footer {
    margin-top: 40px;
    text-align: center;

    .ant-typography {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.field-group {
  min-height: calc(100vh - 175px);

  .repeat-title {
    padding: 10px 45px;
    background-color: #f0f0f0;
    margin: 40px -25px 20px;

    .icon,
    .icon:focus {
      color: #6a6a6a;

      &:hover {
        color: #222;
      }
    }
  }

  &.ant-card-bordered {
    border-bottom: none;
  }

  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      padding-top: 30px;
      padding-bottom: 0;
    }
  }

  &.last {
    min-height: calc(100vh - 95px);
  }

  .field-label {
    .field-text {
      font-size: 14px;
    }

    .help {
      .ant-btn {
        border-color: #6a6a6a;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 5px;

        &:focus,
        &:visited,
        &:hover {
          background-color: #6a6a6a;
          color: #fff;
        }
      }

      p {
        font-size: 14px;
        margin-top: 14px;
        display: block;
      }

      .help-text {
        font-size: 14px;
        display: block;
      }
    }

    i.double-entry-text {
      color: #d74439;
    }
  }

  .field-group-header {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: bold;
    padding: 0px 20px;

    .field-group-heading {
      display: inline-block;
      padding-right: 20px;
      font-weight: bold;
    }

    .repeat-input {
      font-weight: normal;
      display: inline-block;
      margin-top: 20px;

      .field-title,
      .field {
        display: inline-block;
        vertical-align: top;
        border: 1px solid #6a6a6a;
        width: unset;
      }

      .field {
        margin-top: 0;
      }

      .field-title {
        padding: 9.5px 12px;
        border-radius: 5px 0px 0px 5px;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.2px;
      }

      .field {
        border-left: none;
        border-radius: 0px 5px 5px 0px;

        &.ant-input-group {
          padding: 0px;

          button,
          .ant-input[disabled] {
            padding: 8.5px;
            border: none !important;
            font-weight: bold;
            background-color: #fff;
            color: #6f6f6f;
          }

          button {
            background: #6a6a6a;
            color: #fff;
            margin: 4px;
            border-radius: 5px;
            height: 33px;
            width: 33px;
            font-weight: bold;

            &.disabled {
              background: #d9d9d9;
            }
          }
        }
      }
    }
  }

  .description {
    font-family: "Assistant", sans-serif;
    font-size: 14px;
    margin: 0px 20px 20px;
  }

  .field {
    font-family: "Assistant", sans-serif;
    padding: 0px 20px;
    margin-top: 12px;
  }

  .field-cascade {
    margin-top: 12px;

    .ant-select {
      display: none;
    }

    .cascade {
      padding: 0px 20px;

      .field-label {
        font-family: "Assistant", sans-serif;

        p {
          margin-bottom: 0px;
        }
      }

      .ant-select {
        margin-top: 8px;
        width: 100%;
        display: inline-block;
      }
    }

    .error-cascade {
      padding: 0 20px;
      font-family: "Assistant", sans-serif;
      color: #ff4d4f;
    }
  }

  .field-geo {
    display: none !important;
  }

  .field-map {
    margin-top: -56px;

    .map-display {
      margin-top: 25px;
      margin-bottom: 8px;
      padding: 10px;
      border: 1px solid #6a6a6a;
      border-radius: 10px;
    }
  }

  .field-double-entry {
    margin: 0 20px;

    .error-double-entry {
      font-family: "Assistant", sans-serif;
      color: #ff4d4f;
    }
  }
}

.hidden {
  display: none;
}

.next {
  border-left: 1px solid #f0f0f0;
  padding: 20px 50px;
}

button,
.button-next {
  border: none;
  background-color: #222;
  border-radius: 5px;
  color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: bold;
  font-size: 14px;
}

.button-next {
  &:focus,
  &:visited,
  &:hover {
    background-color: #222;
    color: #fff;
  }
}

.button-secondary {
  border: none;
  background-color: #929292;
  border-radius: 5px;
  color: #fff;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: bold;
  font-size: 14px;
}

.button-secondary {
  &:focus,
  &:visited,
  &:hover {
    background-color: #929292;
    color: #fff;
  }
}

.submissions-drawer-toggle,
.submissions-drawer-toggle-close {
  width: 6px;
  height: 50px;
  border: 2px solid #6a6a6a;
  cursor: pointer;
  top: 50%;
}

.submissions-drawer-toggle {
  position: fixed;
  left: 4px;
  border-left: none;

  &:hover {
    border: 3px solid #222;
    border-left: none;
  }
}

.submissions-drawer-toggle-close {
  position: absolute;
  right: 4px;
  border-right: none;

  &:hover {
    border: 3px solid #222;
    border-right: none;
  }
}

.submissions-drawer-container {
  .ant-drawer-header {
    padding: 16px 0;

    .ant-drawer-close {
      &:hover,
      &:focus {
        background: none;
      }
    }
  }

  .submission-list-select {
    width: 100%;
  }
}

.mobile-footer-container {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1002;
  padding: 20px;
  border-top: 1px solid #d9d9d9;
  background-color: #fff;
}

.translation-text {
  color: #2b78e4;

  &.sidebar-list-item {
    margin-left: 24px;
  }
}

.notification-modal-wrap {
  .info-text {
    font-size: 20px;
    color: #2b78e4;
    font-weight: 600;
  }

  .button-next {
    border: 1px solid #222;

    &:disabled {
      border: 1px solid #d9d9d9;
    }
  }

  .ant-btn {
    border-radius: 5px;
    min-width: 120px;
    font-size: 14px;

    &.button-default {
      border-color: #222;
      color: #222;

      &:hover {
        color: #222;
        border-color: #222;
      }
    }
  }
}

.captcha-container {
  #captcha-number {
    border: 1px solid #6f6f6f;
    border-radius: 5px 5px 0 0;
    padding: 1px;
    border-bottom: none;
  }

  .ant-input-number {
    border: 1px solid #6f6f6f;
    border-radius: 0 0 5px 5px;
  }
}

.ant-input-group-wrapper {
  span {
    &.ant-input-group-addon {
      background: #ffffff;
      border: 1px solid #6a6a6a;
      border-left: 0px;
      border-radius: 5px;
      font-weight: 600;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .ant-input-group {
    .ant-input {
      &:first-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
  }
}

.info-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .info-page-header-wrapper {
    padding: 0;
    margin-bottom: 24px;
  }

  .info-page-header {
    text-align: center;
  }

  .info-page-content {
    padding: 5px;
  }

  .info-page-footer {
    margin-top: 0;
  }
}

.option-answer-stats-wrapper {
  margin-left: 10px;

  .ant-spin-sm .ant-spin-dot {
    margin-left: 5px;
    font-size: 12px;
  }
}

.input-answer-stats-wrapper {
  margin-left: 10px;

  .ant-spin-sm .ant-spin-dot {
    margin-left: 5px;
    font-size: 12px;
  }

  .input-answer-stats-item {
    background-color: #efefef;
    padding: 0 5px;
  }
}

@media only screen and (max-width: 1064px) {
  .login-container {
    padding: 0 0 70px 0;

    .login-header-wrapper {
      padding: 26px 0;
      top: 0;
      height: fit-content;
      position: sticky;
      position: -webkit-sticky;
      border-bottom: 1px solid #d9d9d9;
      z-index: 9999;
    }

    .login-header {
      h1 {
        &.logo {
          margin-right: 16px;

          img {
            height: 38px;
            width: 38px;
          }
        }
      }
    }
  }

  .ant-card-head {
    padding: 0;
  }

  .ant-card-body {
    padding: 12px 0;
  }

  .form-header {
    height: 79px;
    padding: 0 20px;

    .form-header-container {
      padding: 14px 0;
    }

    &.mobile-header-with-display-name {
      height: 115px;
    }

    .form-header-container {
      .right {
        h1 {
          font-size: 16px;
        }

        h1.logo {
          padding: 0 8px;
          margin-right: 8px;
          border-radius: 5px;
        }
      }

      .left {
        .more {
          font-size: 20px;
          vertical-align: middle;
          margin-bottom: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .datapoint {
    color: #6a6a6a;
    padding: 0;
    margin-top: 12px;
    display: inline-block;
    font-weight: bold;

    .icon {
      font-size: 24px;
      margin-bottom: -6px;
    }
  }

  .field-group .repeat-title {
    margin: 20px 0px;
    padding: 10px 20px;
  }

  .notification-modal-wrap {
    .ant-modal-body {
      padding: 0;

      .ant-result-icon {
        .anticon {
          font-size: 64px;
        }
      }

      .ant-result-title {
        font-size: 18px;
      }

      .info-text {
        font-size: 16px;
        color: #2b78e4;
      }
    }
  }

  .info-page-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .info-page-header-wrapper {
      padding: 0;
      position: relative;
      border: none;
    }

    .info-page-content {
      span.anticon.anticon-form {
        font-size: 60px;
      }

      .ant-result-title {
        font-size: 20px;
      }
    }

    .info-page-header {
      text-align: center;
    }

    .info-page-footer {
      margin-top: 0;
    }
  }
}

pre {
  font-size: 12px;
  background: #efefef;
  padding: 3px 5px;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #ddd;
}

ol {
  &.info {
    padding-inline-start: 25px;
    margin-block-start: 0px;

    li {
      margin: 10px;
    }

    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  &.info-banner {
    padding-inline-start: 15px;
    margin-block-start: 0px;
  }
}
